/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

.pure-form fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

.btn {
  border-style: none;
  border-radius: 0;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: background .7s, color .7s;
  outline: none;
  display: inline-block;
}

.btn-primary {
  background: var(--color-primary);
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0.3rem;
  box-shadow: 0px 6px 0px 0px var(--color-secondary);
  -moz-box-shadow: 0px 6px 0px 0px var(--color-secondary);
  -webkit-box-shadow: 0px 6px 0px 0px var(--color-secondary);
}

.btn-primary:hover {
  background: var(--color-secondary);
  box-shadow: 0px 6px 0px 0px var(--color-primary);
  -moz-box-shadow: 0px 6px 0px 0px var(--color-primary);
  -webkit-box-shadow: 0px 6px 0px 0px var(--color-primary);
}

.btn-secondary {
  background: var(--color-secondary);
  color: white;
}

.btn-secondary:hover {
  background: color(var(--color-secondary) a(80%));
  color: white;
}

.pure-form input[type="text"], .pure-form input[type="password"], .pure-form input[type="email"], .pure-form input[type="url"], .pure-form input[type="date"], .pure-form input[type="month"], .pure-form input[type="time"], .pure-form input[type="datetime"], .pure-form input[type="datetime-local"], .pure-form input[type="week"], .pure-form input[type="number"], .pure-form input[type="search"], .pure-form input[type="tel"], .pure-form input[type="color"], .pure-form select,
.pure-form textarea {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  line-height: 1rem;

}

#checkbox-ds {
  font-size: 0.7rem;
}

.pure-form input[type="text"], .pure-form input[type="password"],
.pure-form input[type="email"], .pure-form input[type="url"],
.pure-form input[type="date"], .pure-form input[type="month"],
.pure-form input[type="time"], .pure-form input[type="datetime"],
.pure-form input[type="datetime-local"], .pure-form input[type="week"],
.pure-form input[type="number"], .pure-form input[type="search"],
.pure-form input[type="tel"], .pure-form input[type="color"],
.pure-form select {
  height: 2.56rem;
}
