.modal {
  background: rgba(25, 25, 25, .95) none repeat scroll 0 0;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 300;
  overflow: scroll;
  width: 100%;
  height: 100vh;
  top: -130%;
  transition: top .5s ease-in;
}
.modal.open {
  top: 0;
}
.modal-content {
  background: white;
  top: 0;
  width: 100vw;
  padding: 4rem 1.5rem;
  position: relative;
}
.modal-transparent .modal-content {
  background: transparent;
}
.modal-header {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
}
/*.modal-header h2 {*/
  /*width: 50%;*/
  /*margin: 0;*/
/*}*/

.modal-header button.close {
  float: right;
  /*background: transparent;*/
  border: none;
  font-size: 3rem;
  font-weight: 100;
  cursor: pointer;
  color: #000;
  line-height: 1.8rem;
  padding: .5rem;
  /*margin-right: .5rem;*/
}
.modal-header button.close:hover,
.modal-header button.close:focus,
.modal-header button.close:active {
  border: none;
  outline: none;
  /*color: var(--color-secondary);*/
}

.modal-header button.close:hover{
  /*color: var(--color-secondary);*/
}
.gallery-top .swiper-slide {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
}
#modal-gallery .modal-header button.close {
  color: white;
}

/* BOOK CLASS FORM MODAL */
div#modal-appointment-form form {
  display:  flex;
  flex-wrap:  wrap;
}

div#modal-appointment-form form label,
div#modal-appointment-form form label input,
div#modal-appointment-form form label textarea {
  width: 100%;
}

div#modal-appointment-form form label {
  padding: .25rem .5rem;
}

div#modal-appointment-form form input,
div#modal-appointment-form form textarea {
  padding: .5rem;
}

#bookClass {
  margin-left: .5rem;
  margin-top: .25rem;
}
/* ./BOOK CLASS FORM MODAL */


/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .modal {
    overflow: hidden;
  }

  .modal-content {
    top: 6rem;
    width: 75vw;
    left: 12.5vw;
    margin-bottom: 4rem;

  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .modal-content {
    top: 6rem;
    width: 65vw;
    left: 25vw;
    padding: 1rem;
  }
}

/* viewport-lg 1280px */
@media screen and (min-width: 80rem) {
  .modal-content {
    top: 6rem;
    width: 60vw;
    left: 25vw;
  }
}
@media screen and (min-width: 100rem) {
  .modal-content {
    top: 6rem;
    width: 50vw;
    left: 25vw;
  }
}
