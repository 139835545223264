.cta {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cta > .container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  background: white;
}

.cta-content {
  width: 80%;
  margin: auto;
}

.cta > .container > a {
  margin-top: 2rem;
}