/*
 * Filename: typo.css
 *
 * Primary stylesheet which imports every other file.
 */



body {
  font-family: var(--font-normal);

  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 500;
  color: var(--font-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin-top: 0;
  margin-bottom: var(--gutter-horizontal);
}

h1 {
  font-family: var(--font-bold);
  font-size: 2.8rem;
  line-height: 4.3rem;
  font-weight: 900;
  color: color(var(--color-primary) a(30%));
  margin-top: 0;
  position: relative;
  margin: 0;
}

.content-header-text h1 {
  color:  #fff;
}

h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 2rem;
  line-height: 2.3rem;
  color: var(--color-primary);
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}

.text-ueber-bild--text--inner h2 {
  color: #fff;
}

h3 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--color-primary);
  text-transform: uppercase;
  margin: 1rem 0;
  text-align: center;
}

.kacheln h3 {
  margin-bottom: 2.5rem;
}

h4 {
  font-family: var(--font-normal);
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--font-color);
  text-align: center;
}



h5 {
  font-family: var(--font-normal);
  font-weight: 500;
  color: var(--font-color);
  font-size: 1.2rem;
  line-height: 1.6rem;


}
h6 {
  font-family: var(--font-normal);
}

small {
  font-size: var(--font-size-small);
}


.pure-g [class *= "pure-u"] {
  font-family: var(--font-normal);
}
.text-bold,
strong, b {
  font-weight: 900;
}

ul {
  padding-left: 24px;
}
li {
  margin-bottom: 0.4rem;
}


/* HEADER SLIDER */

.slider-wrapper.slider h1,
.slider-wrapper.slider p {
  color: #fff;
}

/*.slider-wrapper.slider h1 {*/
  /*font-size: 3.8rem;*/
/*}*/

.slider-wrapper.slider p {
  font-size: 3.3rem;
  line-height: 3.8rem;
  font-weight: 900;
  /*font-family: "Open Sans", sans-serif;*/
}

@media screen and (--viewport-md) {
}
