/*
 * Filename: theme.css
 *
 * Stylesheet for all theme relevant attributes like colors, fonts ...
 */

/*@font-face {*/
  /*font-family: 'Papyrus';*/
  /*src: url('fonts/Papyrus-Regular.eot');*/
  /*src: url('fonts/Papyrus-Regular.eot?#iefix') format('embedded-opentype'),*/
  /*url('fonts/Papyrus-Regular.woff') format('woff'),*/
  /*url('fonts/Papyrus-Regular.ttf') format('truetype');*/
  /*font-weight: normal;*/
  /*font-style: normal;*/
/*}*/

@font-face {
  font-family: 'Bell Gothic Std';
  src: url('fonts/BellGothicStd-Bold.woff2') format('woff2'),
  url('fonts/BellGothicStd-Bold.woff') format('woff'),
  url('fonts/BellGothicStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bell Gothic Std';
  src: url('fonts/BellGothicStd-Black.woff2') format('woff2'),
  url('fonts/BellGothicStd-Black.woff') format('woff'),
  url('fonts/BellGothicStd-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Bell Gothic Std';
  src: url('fonts/BellGothicStd-Light.woff2') format('woff2'),
  url('fonts/BellGothicStd-Light.woff') format('woff'),
  url('fonts/BellGothicStd-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('fonts/open-sans-v15-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
    url('fonts/open-sans-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-v15-latin-800.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-v15-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-condensed-300 - latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/open-sans-condensed-v13-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
    url('fonts/open-sans-condensed-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/open-sans-condensed-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/open-sans-condensed-v13-latin-300.woff') format('woff'), /* Modern Browsers */
    url('fonts/open-sans-condensed-v13-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/open-sans-condensed-v13-latin-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}



:root {

  /** Schriftgrößen */
  --font-size: 1rem;
  --font-size-title: 2rem;
  --font-size-heading: 1.7rem;
  --font-size-subheading: 1.25rem;
  --font-size-small: .8125rem;

  /** Raster */
  --line-height: 1.5em;

  --gutter: 1rem;
  --gutter-horizontal: 1rem;

  /** Farben */
  --font-color: #828385;
  --code: #c7254e;
  --error: #c7254e;
  --success: #38ae67;
  --color-grey-light: #ccc;
  --color-grey-dark: #333;
  --color-primary: #a5C500;
  --color-secondary: #c8d96f;
  --color-primary-hover: #a5C500;

  --color-background-dark: #f4f4f4;

  --theme-path: '';
  /** Fonts **/

  /*--font-normal: 'Open Sans', sans-serif;*/
  /*--font-light: 'Open Sans', sans-serif;*/
  /*--font-bold: 'Open Sans', sans-serif;*/

  --font-normal: 'Bell Gothic Std', sans-serif;
  --font-light: 'Bell Gothic Std', sans-serif;
  --font-bold: 'Bell Gothic Std', sans-serif;
}


@custom-media --viewport-sm (min-width: 35.5rem);
@custom-media --viewport-md (min-width: 48rem);
@custom-media --viewport-lg (min-width: 64rem);
@custom-media --viewport-xl (min-width: 80rem);
