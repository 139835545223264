.slider .swiper-slide {
  height: 200px;
  background-size: cover;
  background-position-y: 50%;
}


.slider .slider-content {
  display:  flex;
  justify-content:  center;
  align-items:  center;
}

.slider .slider-content,
.slider .content-slider-wrapper .container,
.slider .content-slider-wrapper,
.slider .container-slider {
  height:  100%;
}

.slider .slider-content p {
  font-size: 1.7rem;
  line-height: 2rem;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0,0,0,.4)
}

/* 1024 px - Laptop */
@media screen and (min-width: 64rem) {
  .slider .swiper-slide {
    height: 500px;
    background-size: cover;
    background-position-y: 50%;
  }
  .slider .slider-content p {
    font-size: 3rem;
    line-height: 3.3rem;
  }
}


/*.slider.slider-wrapper {*/
/*margin-top: 0;*/
/*margin-bottom: 0;*/
/*}*/
/*.slider .swiper-container {*/
/*width: 100%;*/
/*}*/

/*!*.slider .swiper-slide {*!*/
/*!*height: 100%;*!*/
/*!*background: white;*!*/
/*!*min-height: 40rem;*!*/
/*!*}*!*/

/*.slider .container-slider {*/
/*width: 100%;*/
/*}*/

/*.slider .content-slider-wrapper {*/
/*background: white;*/
/*height: 100%;*/
/*bottom: 0;*/
/*}*/
/*.content-slider-wrapper > .container {*/
/*height: 100%;*/
/*}*/
/*.slider .slider-content {*/
/*height: 100%;*/
/*width: 100%;*/
/*display: flex;*/
/*justify-content: center;*/
/*align-items: center;*/
/*align-content: center;*/
/*flex-direction: column;*/
/*text-align: center;*/
/*padding: 2rem;*/
/*}*/

/*.slider .slider-content h2 {*/
/*line-height: 2.5rem;*/
/*font-weight: 500;*/
/*text-transform: none;*/
/*font-size: 2.3rem;*/
/*color: var(--font-color);*/
/*font-family: "Open Sans Condensed";*/
/*}*/
/*.slider .slider-content b {*/
/*font-family: "Open Sans";*/
/*font-weight: bold;*/
/*}*/
/*.slider .slider-content p {*/
/*font-size: 1.6rem;*/
/*font-weight: 300;*/
/*}*/

/*.slider .slide--button-container {*/
/*text-transform: none;*/
/*}*/
/*.slider .slider-img {*/
/*width: 100%;*/
/*display: block;*/
/*}*/
/*.slider .slider-img img {*/
/*width: 100%;*/
/*display: block;*/
/*}*/
/*.slider .slider-page .swiper-pagination-bullet {*/
/*background: white;*/
/*opacity: 1;*/
/*}*/
/*.slider .slider-page .swiper-pagination-bullet-active {*/
/*opacity: 1;*/
/*background: var(--color-primary);*/
/*}*/

/*.slider .slider-content,*/
/*.slider .slider-content span,*/
/*.slider .slider-content p,*/
/*.slider .slider-content h2 {*/
/*color: var(--font-color);*/
/*}*/

/*.slider .swiper-slide {*/
/*height: 200px;*/
/*background-size:  cover;*/
/*background-position-y:  50%;*/
/*}*/

/*@media screen and (min-width: 48rem) {*/
/*!*.slider .swiper-slide {*!*/
/*!*min-height: 20rem;*!*/
/*!*}*!*/
/*}*/
/*@media screen and (min-width: 64rem) {*/
/*.slider .swiper-slide {*/
/*height: 500px;*/
/*background-size:  cover;*/
/*background-position-y:  50%;*/
/*}*/

/*!*.slider .container-slider {*!*/
/*!*height: 100%;*!*/
/*!*}*!*/
/*.slider .content-slider-wrapper {*/
/*background: transparent;*/
/*position: absolute;*/
/*top: 0;*/
/*bottom: 0;*/
/*left: 0;*/
/*right: 0;*/
/*}*/
/*.slider .slider-content {*/
/*width: 70%;*/
/*align-items: flex-start;*/
/*align-content: center;*/
/*flex-direction: column;*/
/*text-align: center;*/
/*}*/
/*.slider .slider-content h2 {*/
/*line-height: 3rem;*/
/*}*/

/*.slider .slider-content,*/
/*.slider .slider-content span,*/
/*.slider .slider-content p,*/
/*.slider .slider-content h2 {*/
/*color: #fff;*/
/*}*/
/*}*/
