.pure-g {
  display: flex;
  flex-wrap: wrap;
}

.container {
  margin: 0 0.5rem;
}

.boxen {
  padding:  0;
}

.leistungs-box {
  margin-bottom:  0;
  margin-top:  0;
}

section {
  margin-bottom:  0;
  margin-top:  0;
  padding-bottom:  0;
  padding-top:  0;
}

@media screen and (--viewport-lg) {
  .container {
    width: 60rem;
    margin: 0 auto;
  }

  section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media screen and (--viewport-xl) {
  .container {
    width: 75rem;
  }
}

section.content-header-text {
  padding:  0;
}
