
.accordion {
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin: 50px auto;
  display: none;
}
.accordion ul {
  width: 100%;
  display: table;
  table-layout: fixed;
  margin: 0;
  padding: 0;
}
.accordion ul li {
  display: table-cell;
  vertical-align: bottom;
  position: relative;
  width: 16.666%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 500ms ease;
}
.accordion ul li div {
  display: block;
  overflow: hidden;
  width: 100%;
}
.accordion ul li div a {
  display: block;
  height: 400px;
  width: 100%;
  position: relative;
  z-index: 3;
  vertical-align: bottom;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  text-decoration: none;
  font-family: Open Sans, sans-serif;
  transition: all 200ms ease;
}
.accordion ul li div a * {
  opacity: 0;
  margin: 0;
  width: 100%;
  text-overflow: ellipsis;
  position: relative;
  z-index: 5;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
/*.accordion ul li div a h2 {*/
  /*text-overflow: clip;*/
  /*font-size: 1.2rem;*/
  /*text-transform: uppercase;*/
  /*margin-bottom: 2px;*/
  /*padding: 0.5rem 1rem;*/
  /*bottom: 0;*/
  /*position: absolute;*/
  /*width: 100%;*/
  /*background: rgba(0,0,0,0.6);*/
  /*text-align: left;*/
  /*font-family: var(--font-normal);*/
/*}*/

.accordion ul li div a:hover {
  color: white;
}
.accordion ul:hover li {
  width: 8%;
}
.accordion ul:hover li:hover {
  width: 60%;
  background-size: cover;
}

.accordion ul:hover li:hover a * {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  color: white;
}
@media screen and (min-width: 48rem) {
  .accordion {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  body {
    margin: 0;
  }

  .accordion {
    height: auto;
  }
  .accordion ul li, .accordion ul li:hover, .accordion ul:hover li, .accordion ul:hover li:hover {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    -webkit-transition: none;
    transition: none;
  }
}

