
.leistungen {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: var(--color-background-dark);
  text-align: center;
}
.leistungen .swiper-slide {
  background: transparent;
}
/*.leistungen > .container > h2 {*/
  /*margin-top: 0;*/
  /*text-transform: uppercase;*/
/*}*/
.swiper-button-next, .swiper-container-rtl .swiper-button-prev, .swiper-button-prev {
  display: none;
}

.swiper-wrapper {
  width: 100%;
}
.leistung {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 26rem;
  width: 95%;
  background: white;
}

.leistung--image {
  width: 100%;
  height: 50%;
  background-size: cover;
}

.leistung-content {
  width: 100%;
  height: 50%;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
}

/*.leistung-content h3{*/
  /*margin-top: 2rem;*/
  /*display: block;*/
/*}*/
.leistungs-icon {
  width: 100%;
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
}
.image-circle {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  background: var(--font-color);
}
.leistungs-icon img{
  display: block;
  position: relative;
  height: 3rem;
  width: 3rem;
}
@media screen and (min-width: 48rem) {
  .leistungen-slider {
    width: 80%;
  }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev, .swiper-button-prev {
    display: block;
  }
}
