.comment {
  background: white;
  padding: 2rem;
  /*padding-left: 5rem;*/
  padding-top: 3rem;
  font-weight: 600;
  color: #9c9c9c;
  text-align: center;
}
.comment--img {
  position: absolute;
  top: 2rem;
  left: 2rem;
}