.termin {
  background: white;
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
/*.termin h3 {*/
  /*!*font-family: 'Papyrus';*!*/
  /*font-size: 1.5rem;*/
  /*font-weight: 400;*/
/*}*/



.termin .pure-g > div {
  padding-right: 1rem;
  padding-left: 1rem;
}

.termin .pure-g > div input,
.termin .pure-g > div select {
  margin-bottom: 19px;
  border-radius: 0;

}
.termin .pure-g > div img{
  height: 90%;
  width: auto;
}
.termin-image {
  text-align: left;
}
input.pure-input-1.input-date {
  height: 2.56rem;
}
.btn-container {
  text-align: left;
}

.termin .pure-form input[readonly],
.termin .pure-form select[readonly],
.termin .pure-form textarea[readonly] {
  background:  #fff;
}

/* 568 px */
@media screen and (min-width: 35.5rem) {
}
/* 768 px - Tablet */
@media screen and (min-width: 48rem) {
}
/* 1024 px - Laptop */
@media screen and (min-width: 64rem) {
  .termin .container {
    padding: 0 12rem;
  }
}
/* 1280 px*/
@media screen and (min-width: 80rem) {
}
