.content {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

p.sub-titel {
  text-align: right;
  padding-top: 0.5rem;
}

.search article {
  margin-bottom: 1rem;
}

.search {
  margin-bottom: 2rem;
}

.content-mutiple-images a {
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}

.content ul {
  margin-left: 1rem;
  list-style: none;
  padding: 0;
}

.content li {
  padding-left: 1.3em;
}

.content li:before {
  content: url("img/haken.png");
  display: inline-block;
  margin-left: -2rem; /* same as padding-left set on li */
  width: 2rem; /* same as padding-left set on li */
  position: absolute;
  top: 0;
  /*transform: translateY(-50%);*/

}

.content a {
  color: var(--color-primary);
}

.content a.btn-primary {
  color: white;
}

.content a:hover.btn-primary {
  color: white;
}

.content a:hover {
  color: color(var(--color-primary) a(60%));
}

.content ul {
  list-style: none;

}

.content > article {
  margin-bottom: calc(var(--gutter) * 2);
  margin-top: calc(var(--gutter) * 2);
}

.content .gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.content .gallery a {
  width: 48%;
  margin-right: 1%;
  display: block;
  position: relative;
}

.content .gallery a.content---image--one {
  width: 100%;
}

.content---image--one {
  padding: 14px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  border-radius: 4px;
}
