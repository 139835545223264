.tabs {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.toggle {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}
.show {
  max-height: 3000px;
}
.tab-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.btn-tab {
  background: var(--font-color);
  color: white;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.btn-tab:hover, .btn-tab.active {
  background: var(--color-primary);

  color: white;
  padding: 0.5rem 1rem;
}
.tab-content-right {
  padding-top: 1rem;
}
.tab-content-bottem {
  padding-top: 1rem;
}
.tab-content-line {
  height: 1px;
  width: 100%;
  background: #cccccc;
  border: none;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.faq-list {
  list-style: none;
  padding-left: 0;
}

.faq-list a{

  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: var(--font-color);
}
.faq-list-text {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
  position: relative;
}
.toggle-cross-faq {
  display: block;
  width: 1rem;
}
.toggle-cross-faq-line {
  backface-visibility: hidden;
  position: absolute;
  left: 0;
  top: 50%;
  border-top: 2px solid var(--color-primary);
  transition: 0.55s;
  width: 14px;
}

.toggle-cross-faq-top {
  transform: rotate(90deg);
}
.faq-list .faq-link {
  padding: 0 0.3rem;
  transition: border 0.5s ease-in;
}
.faq-list .faq-link:hover {
  border-color: #cccccc;
}

.faq-list .faq-link.active {
  border: 1px solid #cccccc;
}

.faq-list .faq-link.active .toggle-cross-faq-top {
  transform: rotate(0deg);
}

.faq-content-text {
  padding-left: 1rem;
}

.show.faq-content{
  padding: 0.5rem;
  border-left: none;
  border-top: solid 1px #cccccc;
}
@media screen and (min-width: 48rem) {
  .faq-list .faq-link.active {
    border-right: none;
  }
  .faq-list .faq-link.active:after {
    position: absolute;
    display: block;
    content: '';
    width: 5px;
    height: 100%;
    top: 0;
    bottom: 0;
    right: -3px;
    background: white;
    z-index: 10;
  }
  .show.faq-content{
    border-left: solid 1px #cccccc;
    border-top: none;
  }
  .tab-content-right {
    padding-left: 1rem;
    padding-top: 0;
  }
}