/* ==========================================================================
   Pure Base
   ========================================================================== */

.hidden,
[hidden] {
    display: none !important;
}

/**
 * Add this class to an image to make it fit within it's fluid parent wrapper while maintaining
 * aspect ratio.
 */
.pure-img {
    max-width: 100%;
    height: auto;
    display: block;
}
