.hinweisbereich {
    margin-top: 0;

    background: white;
}

.hinweisbereich .container {
    margin: 0 0.5rem;
}

.hinweis-box.user {
    text-align: center;
}

.pure-u-1.pure-u-md-1-2.hinweis-text {
    padding-left: 0;
}

.hinweis-box .hinweis-box--image {
    margin: auto;
}

.hinweis-boxen {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
}

.hinweis-box {
    width: 100%;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 1rem;
    background: white;
    border-top: 2px solid #f3f3f3;
}

.kurs__hinweis {
    margin-top: 10px;
}

#vn-bilder {
    display: block;
    position: relative;
    width: 100%;
    overflow-y: visible;
    background: #fafaf8;;
}

.vn-bilder-slide {
    width: 100%;
    display: block;
}

.hinweis-box--image-wrapper {
    display: flex;
    justify-content: flex-end;
}

.hinweis-box .hinweis-box--image {
    width: auto;
    display: inline-block;
    height: 100%;
    margin-left: auto;
}

.kurse .swiper-wrapper {
    align-items: center;
}

.hinweis-box .hinweis-box--image img {
    height: 20rem;
}

.kurse__link {
    margin-top: 20px;
}

.hinweisbereich .slider-content {
    /*height: 18rem;*/
    /*min-height: 350px;*/
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

/*.vn-bilder-slide::after,*/
/*.hinweis-box .hinweis-box--image::after {*/
    /*content: ' ';*/
    /*position: absolute;*/
    /*top: 0.5rem;*/
    /*left: 0.5rem;*/
    /*right: 0.5rem;*/
    /*bottom: 0.5rem;*/
    /*!*background: #ededed;*!*/
    /*border: 1px solid #fff;*/
    /*z-index: 3;*/
/*}*/

#vn-bilder.swiper-container {
    z-index: 3;
}

#vn-bilder.swiper-container::after {
    content: ' ';
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 40px;
    /*background: #ededed;*/
    border: 1px solid #fff;
    z-index: -1;
}

/*.hinweis-box--image-wrapper .hinweis-box--image::after {*/
    /*bottom: 0.9rem;*/
/*}*/

/*.vn-bilder-slide::after {*/
    /*bottom: 3.5rem;*/
/*}*/

.vn-bilder-slide img {
    padding-bottom: 3rem;
}

.hinweisbereich .hinweis-box .swiper-pagination-bullet {
    border: solid 1px var(--color-primary);
    background: white;
    opacity: 1;
}

.hinweisbereich .hinweis-box .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--color-primary);
}

.hinweis-box {
    padding: 0 1rem;
}

.hinweis-box {
    border: none;
}

/*.hinweis-box::after {*/
    /*content: '';*/
    /*position: absolute;*/
    /*width: 80%;*/
    /*height: 1rem;*/
    /*top: -12px;*/
    /*left: 50%;*/
    /*transform: translate(-50%, 50%);*/
    /*border-top: 7px double #f3f3f3;*/
/*}*/

.hinweis-box.user .hinweis-text {
    padding-left: 1rem;
}

.hinweis-text ul {
    display: flex;
    flex-wrap: wrap;
}

.hinweis-text ul li {
    width: 100%;
}

/* KURS SLIDER */
.hinweisbereich .swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    background: #fff;
    bottom: 0;
    padding-top: 8px;
}

/*.kurse .vn-bilder-slide::before,*/
/*.kurse .hinweis-box--image::before {*/
    /*content: '';*/
    /*position: absolute;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*top: 0;*/
    /*left: 0;*/
    /*background: #fafaf8;*/
    /*z-index: 3;*/
/*}*/

/*.kurse .swiper-slide.vn-bilder-slide.swiper-slide-active::before,*/
/*.kurse .swiper-slide.vn-bilder-slide.swiper-slide-prev::before,*/
/*.kurse .swiper-slide.vn-bilder-slide.swiper-slide-next::before {*/
    /*content: '';*/
    /*position: absolute;*/
    /*width: 100%;*/
    /*height: auto;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*background: #fafaf8;*/
    /*z-index: 1;*/
    /*bottom: 3rem;*/
/*}*/

.hinweisbereich .slider-content p {
    font-size: 1rem;
    /*font-family: "Open Sans", sans-serif;*/
}

.hinweisbereich .slider-content button {
    display: inline-block;
    width: auto;

}

@media screen and (min-width: 35.5rem) {
    .hinweis-text ul li {
        width: 50%;
    }
}

/* 768 px - Tablet */
@media screen and (min-width: 48rem) {
    .pure-u-1.pure-u-md-1-2.hinweis-text {
        text-align: left;
    }
}

@media screen and (min-width: 64rem) {
    .hinweis-box.kurse {
        margin-top: 1.5rem;
    }

    .hinweis-box {
        width: 50%;
        margin: 0;
    }

    .hinweisbereich .container {
        width: 61rem;
        margin: 0 auto;
    }

}

@media screen and (min-width: 80rem) {
    .hinweisbereich .container {
        width: 76rem;
    }
}

