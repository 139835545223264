.referenz h3 {
    color: var(--font-color);
    font-size: 18px;
    line-height: 24px;
    text-transform: none;
    text-align: left;
    font-weight: 800;
}

.kurse__hinweis {
    margin-top: 24px;
}

.referenz table td:first-child {
    width: 110px;
}

.referenz .modal-open.btn {
    margin-bottom: 32px
}