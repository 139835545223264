
.layout-2 {
  text-align: center;
}

.layout-2 .content-left  {
  margin-left: 0;
  position: relative;
  display: block;
}

.layout-2 .content-right {
  padding: 1rem;
  background: white;
  height: 100%;
}
/*.layout-2 h2 {*/
  /*font-weight: 600;*/
  /*text-transform: uppercase;*/
  /*display: block;*/
  /*margin-top: 0;*/
  /*margin-bottom: 1rem;*/
  /*position: relative;*/
/*}*/
.layout-2 .header-trenner {
  width: 100%;
  margin: auto;
  height: 1px;
  background: var(--font-color);
  margin-bottom: 1rem;
}
@media screen and (min-width: 48rem) {
  .layout-2 .content-left {
    margin-right: 0.5rem;
  }
  .layout-2 {
    text-align: left;
  }
}
