.leistungs-box__title {
    max-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
}

.leistungs-box__text {
    height: 192px;
}

.leistungs-box__title h3 {
    margin: 0;
}

.kacheln {
    margin: 40px 0;
    padding: 0;
}

.leistungs-box {
    margin-bottom: 0;
}