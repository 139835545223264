.kacheln {
    margin-top: var(--gutter);
}

.kacheln .container {
    margin: 0 0.5rem;
}

@media screen and (min-width: 64rem) {
    .kacheln .container {
        width: 61rem;
        margin: 0 auto;
    }
}

@media screen and (min-width: 80rem) {
    .kacheln .container {
        width: 76rem;
    }
}

.swiper-slide.leistungs-box {
    min-height: 536px;
    /*max-width: 430px;*/
}

.boxen {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;
    /*padding-bottom: 2rem;*/
}

.leistungs-box {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    background: white;
}

/*.leistungs-box h3{*/
/*margin-top: 1rem;*/
/*margin-bottom: 1rem;*/
/*padding: 1rem;*/
/*text-transform: uppercase;*/
/*font-family: "Open Sans Condensed";*/
/*font-weight: 700;*/
/*color: var(--color-primary);*/
/*}*/
.leistungs-box .leistungs-box--image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.leistungs-box .leistungs-box--content {
    padding: 1rem;
}

.leistungs-box--link {
    display: block;
    width: 100%;
    padding-top: 1rem;
    font-weight: 600;
    text-transform: uppercase;
}

@media screen and (min-width: 48rem) {
    .leistungs-box {
        width: calc(100% / 2 - 1rem);
        margin-right: 0.5rem;
        margin-left: 0.5rem;

    }
}

@media screen and (min-width: 64rem) {
    .leistungs-box {
        width: calc(100% / 3 - 1rem);
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }

    .swiper-container.leistungs-swiper::before {
        width: calc((100% - 60rem) / 2);
    }

    .swiper-container.leistungs-swiper::after {
        width: calc((100% - 60rem) / 2);
    }
}

/* slider */
.swiper-container.leistungs-swiper {
    position: relative;
}

.swiper-container.leistungs-swiper::before {
    content: '';
    position: absolute;
    width: calc((100% - 75rem) / 2);
    /*height: calc(100% - 16px);*/
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(125, 185, 232, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(125, 185, 232, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(125, 185, 232, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#007db9e8', GradientType=1);
}

.swiper-container.leistungs-swiper::after {
    content: '';
    position: absolute;
    width: calc((100% - 75rem) / 2);
    /*height: calc(100% - 16px);*/
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 30;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

.swiper-button-prev.leistung-button-prev {
    z-index: 40;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23a5C500'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.leistung-button-next {
    z-index: 40;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23a5C500'%2F%3E%3C%2Fsvg%3E");
}

@media screen and (min-width: 80rem) {
    .swiper-container.leistungs-swiper::before {
        width: calc((100% - 75rem) / 2);
    }

    .swiper-container.leistungs-swiper::after {
        width: calc((100% - 75rem) / 2);
    }
}

