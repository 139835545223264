/*!
 * icheck-bootstrap v1.0.7 (https://github.com/bantikyan/icheck-bootstrap)
 * Copyright 2016 Hovhannes Bantikyan.
 * Licensed under MIT (https://github.com/bantikyan/icheck-bootstrap/blob/master/LICENSE)
 */
@font-face {
  font-family: 'Glyphicons Halflings';

  src: url('fonts/glyphicons-halflings-regular.eot');
  src: url('fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('fonts/glyphicons-halflings-regular.woff') format('woff'), url('fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: 6px;
}

.radio,
.checkbox {
  min-height: 18px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 0px;
  text-align: left;
}

.radio-inline,
.checkbox-inline {
  margin-top: 0px !important;
}

.radio > label,
.checkbox > label {
  padding-left: 29px;
  min-height: 18px;
  line-height: 18px;
  display: inline-block;
  font-size: 1rem;
  text-align: left;
  font-weight: 200;
  font-family: var(--font-light);
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  position: absolute;
}

.radio > input[type="radio"]:not(:checked) + label::before,
.checkbox > input[type="checkbox"]:not(:checked) + label::before,
.radio > input[type="radio"]:not(:checked) + input[type="hidden"] + label::before,
.checkbox > input[type="checkbox"]:not(:checked) + input[type="hidden"] + label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid #D3CFC8;
  border-radius: 0px;
  margin-left: -29px;
}

.radio > input[type="radio"]:not(:checked) + label::before,
.radio > input[type="radio"]:not(:checked) + input[type="hidden"] + label::before {
  border-radius: 50%;
}

.radio > input[type="radio"]:checked + label::before,
.checkbox > input[type="checkbox"]:checked + label::before,
.radio > input[type="radio"]:checked + input[type="hidden"] + label::before,
.checkbox > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  content: "\e013";
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-weight: normal;
  font-size: 10px;
  color: #fff;
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 20px;
  border: 1px solid #000;
  border-radius: 0px;
  background-color: #000;
  text-align: center;
  margin-left: -29px;
  vertical-align: text-top;
}

.radio > input[type="radio"]:checked + label::before,
.radio > input[type="radio"]:checked + input[type="hidden"] + label::before {
  border-radius: 50%;
}

.radio > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.checkbox > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.radio > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.checkbox > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border: 2px solid #000;
}

.radio > input[type="radio"]:disabled + label,
.radio > input[type="radio"]:disabled + label::before,
.checkbox > input[type="checkbox"]:disabled + label,
.checkbox > input[type="checkbox"]:disabled + label::before,
.radio > input[type="radio"]:disabled + input[type="hidden"] + label,
.radio > input[type="radio"]:disabled + input[type="hidden"] + label::before,
.checkbox > input[type="checkbox"]:disabled + input[type="hidden"] + label,
.checkbox > input[type="checkbox"]:disabled + input[type="hidden"] + label::before {
  pointer-events: none;
  cursor: default;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.radio > input[type="radio"],
.checkbox > input[type="checkbox"] {
  opacity: 0;
  margin-left: 0px;
}

.radio > input[type="radio"]:disabled,
.checkbox > input[type="checkbox"]:disabled {
  opacity: 0;
  cursor: default;
}

/* not checked hover style*/
.icheck-default > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-default > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-default > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-default > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #adadad;
}

/*checked style*/
.icheck-default > input[type="radio"]:checked + label::before,
.icheck-default > input[type="checkbox"]:checked + label::before,
.icheck-default > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-default > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

/* not checked hover style*/
.icheck-primary > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-primary > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-primary > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-primary > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: var(--color-primary);
}

/*checked style*/
.icheck-primary > input[type="radio"]:checked + label::before,
.icheck-primary > input[type="checkbox"]:checked + label::before,
.icheck-primary > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-primary > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

/* not checked hover style*/
.icheck-success > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-success > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-success > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-success > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #4cae4c;
}

/*checked style*/
.icheck-success > input[type="radio"]:checked + label::before,
.icheck-success > input[type="checkbox"]:checked + label::before,
.icheck-success > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-success > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

/* not checked hover style*/
.icheck-info > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-info > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-info > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-info > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #46b8da;
}

/*checked style*/
.icheck-info > input[type="radio"]:checked + label::before,
.icheck-info > input[type="checkbox"]:checked + label::before,
.icheck-info > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-info > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #5bc0de;
  border-color: #46b8da;
}

/* not checked hover style*/
.icheck-warning > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-warning > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-warning > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-warning > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #eea236;
}

/*checked style*/
.icheck-warning > input[type="radio"]:checked + label::before,
.icheck-warning > input[type="checkbox"]:checked + label::before,
.icheck-warning > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-warning > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #f0ad4e;
  border-color: #eea236;
}

/* not checked hover style*/
.icheck-danger > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-danger > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-danger > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-danger > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #d43f3a;
}

/*checked style*/
.icheck-danger > input[type="radio"]:checked + label::before,
.icheck-danger > input[type="checkbox"]:checked + label::before,
.icheck-danger > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-danger > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #d9534f;
  border-color: #d43f3a;
}

/* not checked hover style*/
.icheck-turquoise > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-turquoise > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-turquoise > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-turquoise > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #1abc9c;
}

/*checked style*/
.icheck-turquoise > input[type="radio"]:checked + label::before,
.icheck-turquoise > input[type="checkbox"]:checked + label::before,
.icheck-turquoise > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-turquoise > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #1abc9c;
  border-color: #1abc9c;
}

/* not checked hover style*/
.icheck-emerland > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-emerland > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-emerland > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-emerland > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #2ecc71;
}

/*checked style*/
.icheck-emerland > input[type="radio"]:checked + label::before,
.icheck-emerland > input[type="checkbox"]:checked + label::before,
.icheck-emerland > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-emerland > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #2ecc71;
  border-color: #2ecc71;
}

/* not checked hover style*/
.icheck-peterriver > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-peterriver > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-peterriver > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-peterriver > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #3498db;
}

/*checked style*/
.icheck-peterriver > input[type="radio"]:checked + label::before,
.icheck-peterriver > input[type="checkbox"]:checked + label::before,
.icheck-peterriver > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-peterriver > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #3498db;
  border-color: #3498db;
}

/* not checked hover style*/
.icheck-amethyst > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-amethyst > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-amethyst > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-amethyst > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #9b59b6;
}

/*checked style*/
.icheck-amethyst > input[type="radio"]:checked + label::before,
.icheck-amethyst > input[type="checkbox"]:checked + label::before,
.icheck-amethyst > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-amethyst > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #9b59b6;
  border-color: #9b59b6;
}

/* not checked hover style*/
.icheck-wetasphalt > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-wetasphalt > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-wetasphalt > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-wetasphalt > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #34495e;
}

/*checked style*/
.icheck-wetasphalt > input[type="radio"]:checked + label::before,
.icheck-wetasphalt > input[type="checkbox"]:checked + label::before,
.icheck-wetasphalt > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-wetasphalt > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #34495e;
  border-color: #34495e;
}

/* not checked hover style*/
.icheck-greensea > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-greensea > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-greensea > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-greensea > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #16a085;
}

/*checked style*/
.icheck-greensea > input[type="radio"]:checked + label::before,
.icheck-greensea > input[type="checkbox"]:checked + label::before,
.icheck-greensea > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-greensea > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #16a085;
  border-color: #16a085;
}

/* not checked hover style*/
.icheck-nephritis > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-nephritis > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-nephritis > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-nephritis > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #27ae60;
}

/*checked style*/
.icheck-nephritis > input[type="radio"]:checked + label::before,
.icheck-nephritis > input[type="checkbox"]:checked + label::before,
.icheck-nephritis > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-nephritis > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #27ae60;
  border-color: #27ae60;
}

/* not checked hover style*/
.icheck-belizehole > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-belizehole > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-belizehole > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-belizehole > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #2980b9;
}

/*checked style*/
.icheck-belizehole > input[type="radio"]:checked + label::before,
.icheck-belizehole > input[type="checkbox"]:checked + label::before,
.icheck-belizehole > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-belizehole > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #2980b9;
  border-color: #2980b9;
}

/* not checked hover style*/
.icheck-wisteria > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-wisteria > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-wisteria > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-wisteria > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #8e44ad;
}

/*checked style*/
.icheck-wisteria > input[type="radio"]:checked + label::before,
.icheck-wisteria > input[type="checkbox"]:checked + label::before,
.icheck-wisteria > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-wisteria > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #8e44ad;
  border-color: #8e44ad;
}

/* not checked hover style*/
.icheck-midnightblue > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-midnightblue > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-midnightblue > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-midnightblue > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #2c3e50;
}

/*checked style*/
.icheck-midnightblue > input[type="radio"]:checked + label::before,
.icheck-midnightblue > input[type="checkbox"]:checked + label::before,
.icheck-midnightblue > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-midnightblue > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #2c3e50;
  border-color: #2c3e50;
}

/* not checked hover style*/
.icheck-sunflower > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-sunflower > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-sunflower > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-sunflower > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #f1c40f;
}

/*checked style*/
.icheck-sunflower > input[type="radio"]:checked + label::before,
.icheck-sunflower > input[type="checkbox"]:checked + label::before,
.icheck-sunflower > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-sunflower > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #f1c40f;
  border-color: #f1c40f;
}

/* not checked hover style*/
.icheck-carrot > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-carrot > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-carrot > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-carrot > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #e67e22;
}

/*checked style*/
.icheck-carrot > input[type="radio"]:checked + label::before,
.icheck-carrot > input[type="checkbox"]:checked + label::before,
.icheck-carrot > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-carrot > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #e67e22;
  border-color: #e67e22;
}

/* not checked hover style*/
.icheck-alizarin > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-alizarin > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-alizarin > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-alizarin > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #e74c3c;
}

/*checked style*/
.icheck-alizarin > input[type="radio"]:checked + label::before,
.icheck-alizarin > input[type="checkbox"]:checked + label::before,
.icheck-alizarin > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-alizarin > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #e74c3c;
  border-color: #e74c3c;
}

/* not checked hover style*/
.icheck-clouds > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-clouds > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-clouds > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-clouds > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #ecf0f1;
}

/*checked style*/
.icheck-clouds > input[type="radio"]:checked + label::before,
.icheck-clouds > input[type="checkbox"]:checked + label::before,
.icheck-clouds > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-clouds > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  color: #95a5a6;
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}

/* not checked hover style*/
.icheck-concrete > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-concrete > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-concrete > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-concrete > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #95a5a6;
}

/*checked style*/
.icheck-concrete > input[type="radio"]:checked + label::before,
.icheck-concrete > input[type="checkbox"]:checked + label::before,
.icheck-concrete > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-concrete > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #95a5a6;
  border-color: #95a5a6;
}

/* not checked hover style*/
.icheck-orange > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-orange > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-orange > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-orange > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #f39c12;
}

/*checked style*/
.icheck-orange > input[type="radio"]:checked + label::before,
.icheck-orange > input[type="checkbox"]:checked + label::before,
.icheck-orange > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-orange > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #f39c12;
  border-color: #f39c12;
}

/* not checked hover style*/
.icheck-pumpkin > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-pumpkin > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-pumpkin > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-pumpkin > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #d35400;
}

/*checked style*/
.icheck-pumpkin > input[type="radio"]:checked + label::before,
.icheck-pumpkin > input[type="checkbox"]:checked + label::before,
.icheck-pumpkin > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-pumpkin > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #d35400;
  border-color: #d35400;
}

/* not checked hover style*/
.icheck-pomegranate > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-pomegranate > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-pomegranate > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-pomegranate > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #c0392b;
}

/*checked style*/
.icheck-pomegranate > input[type="radio"]:checked + label::before,
.icheck-pomegranate > input[type="checkbox"]:checked + label::before,
.icheck-pomegranate > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-pomegranate > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #c0392b;
  border-color: #c0392b;
}

/* not checked hover style*/
.icheck-silver > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-silver > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-silver > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-silver > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #bdc3c7;
}

/*checked style*/
.icheck-silver > input[type="radio"]:checked + label::before,
.icheck-silver > input[type="checkbox"]:checked + label::before,
.icheck-silver > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-silver > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

/* not checked hover style*/
.icheck-asbestos > input[type="radio"]:not(:checked):not(:disabled):hover + label::before,
.icheck-asbestos > input[type="checkbox"]:not(:checked):not(:disabled):hover + label::before,
.icheck-asbestos > input[type="radio"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-asbestos > input[type="checkbox"]:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: #7f8c8d;
}

/*checked style*/
.icheck-asbestos > input[type="radio"]:checked + label::before,
.icheck-asbestos > input[type="checkbox"]:checked + label::before,
.icheck-asbestos > input[type="radio"]:checked + input[type="hidden"] + label::before,
.icheck-asbestos > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  background-color: #7f8c8d;
  border-color: #7f8c8d;
}