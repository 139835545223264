/* allgemeine Infos und viewport-sx 320px */
.mobile-navigation {
  font-size: var(--font-size);
  overflow-y: scroll;
  overflow-x: scroll;
}


.mobile-navigation ul {
  list-style: none;
  color: black;
  text-align: left;
  font-size: 1rem;
}
.mobile-navigation > ul {
  margin-left: 0;
  padding-left: 0;
}
.mobile-navigation ul a {
  color: black;
  width: 100%;
  display: block;
  padding: 0.5rem;
}
.mobile-navigation ul li.active > a {

  color: white;
  background: var(--color-primary)
}
.mobile-navigation ul.second-level {
  display: block;

}
.mobile-navigation li.dropdown-list-item{
  width: 100%;
  color: black;
}
.mobile-navigation .dropdown-link {
  color: black;
  margin: 0;
  padding: 0.5rem;
}
.mobile-navigation .dropdown-link:hover,
.mobile-navigation .dropdown-link:focus {
  color: var(--color-primary)
}