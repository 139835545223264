

/* SubFooter*/
.sub-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  background: #525252;
  color: white;
}
.sub-footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.sub-footer ul > li{
  color: white;
  padding: .4rem;
}

.sub-footer  a{
   color: white;
 }

.footer--social {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.footer--social-box {
  background: white;
  border-radius: 10rem;
  padding: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
  margin: 0.5rem;
  transition: background 0.5s ease-in;
}

.footer--social-box svg {
  fill: var(--color-primary);
  height: 1.5rem;
  width: 1.5rem;
  transition: fill 0.5s ease-in;
}
.footer--social-box:hover {
  /*background: var(--color-secondary);*/
  background: var(--color-primary);
}
.footer--social-box:hover svg {
  fill: white;
}

@media screen and (min-width: 35.5rem) {

}

@media screen and (min-width: 48rem) {
  .marken-footer-text {
    margin-right: 2rem;
    margin-bottom: 0;
  }
  .sub-footer ul > li {
    padding: 1rem 1rem;
  }
  .sub-footer ul > li ::after {
    position: absolute;
    right: -1.4rem;
    top: 50%;
    transform: translateY(-50%);
    content: '•';
    font-size: 2rem;
    margin: 0;
    padding: 0;
    line-height: 1rem;
  }
  .sub-footer ul > li:last-of-type ::after {
    content: '';
  }
}

/* 1024 px - Laptop */
@media screen and (min-width: 64rem) {
  .sub-footer {
    padding-bottom: 8px;
  }

}
/* 1280 px*/
@media screen and (min-width: 80rem) {
}