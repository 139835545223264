.boxen-leistung {
  padding-top: var(--gutter);
  background: white;
}

.boxen-leistung .container {
  margin: 0 0.5rem;
}

@media screen and (min-width: 64rem) {
  .boxen-leistung .container {
    width: 61rem;
    margin: 0 auto;
  }
}
@media screen and (min-width: 80rem) {
  .boxen-leistung .container {
    width: 76rem;
  }
}


.boxen-leistung .boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  padding-bottom: 2rem;
}

.box-leistung {
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
  background: white;
}
.box-leistung.box-leistung--header {
  background: url("img/pattern.jpg");
  height: 11.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 3.5rem;
}

.box-leistung .box-leistung--image img{
  width: 100%;
  height: auto;
}
.box-leistung .box-leistung--content {
  padding: 1rem 0.5rem;
  /*font-family: "Open Sans Condensed";*/
  font-size: 1.1rem;
}
.box-leistung--link {
  display: block;
  width: 100%;
  font-size: 1rem;
  padding-top: 1rem;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
}

/*.leistungs-box h3 {*/
  /*font-weight: 300;*/
  /*font-size:  1.6rem;*/
/*}*/

a.leistungs-box--link {
  font-weight: 400;
}

.leistungs-box--image {
  height: 16rem;
}

.leistungs-box--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



@media screen and (min-width: 35.5rem) {
  .box-leistung {
    width: calc(100% / 2  - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 64rem) {
  .box-leistung {
    width: calc(100% / 4 - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

